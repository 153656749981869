export const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL ? process.env.NEXT_PUBLIC_API_URL : ":-err-1:"

export const NEXT_PUBLIC_ASSETS_URL = process.env.NEXT_PUBLIC_ASSETS_URL || ":-err-2:"

export const REDIRECT_AFTER_SUCCESSFUL_LOGIN = "/area-utente"
export const MAX_WIDTH = 1200
export const MAX_HEIGHT = 1200

export const DEVICES_QUERY = {
  xs: "(max-width: 575px)",
  sm: "(min-width: 576px) and (max-width: 768px)",
  md: "(min-width: 769px) and (max-width: 992px)",
  lg: "(min-width: 993px) and (max-width: 1200px)",
  xl: "(min-width: 1201px) and (max-width: 1600px)",
  xxl: "(min-width: 1601px)",
  medium: "(min-width: 576px) and (max-width: 1199px)",
}

export const convertToArrayIfNot = arr => (arr ? (!Array.isArray(arr) ? [arr] : arr) : [])

export const isBrowser = () => typeof window !== "undefined"

export function imageSrcHelper(record, format, idx = 0) {
  record = convertToArrayIfNot(record)

  const img = record[idx]

  return img && typeof img === "string" && img.startsWith("data:")
    ? img
    : img && img.url && typeof img.url === "string"
    ? format && img.formats && img.formats[format]?.url
      ? img.formats[format].url.startsWith("http")
        ? img.formats[format].url
        : `${NEXT_PUBLIC_ASSETS_URL}${img.formats[format].url}`
      : img.url.startsWith("http")
      ? img.url
      : `${NEXT_PUBLIC_ASSETS_URL}${img.url}`
    : undefined
}

/**
 * **Return the right error from Axios**
 *
 * @param {*} error Axios error
 */
export function parseAxiosError(error) {
  // Error 😨
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */

    // dLog(error.response.data)
    // dLog(error.response.status)
    // dLog(error.response.headers)
    return { ...error.response, isError: true }
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */

    // dLog(error.request)
    return { ...error.request, isError: true }
  } else {
    // Something happened in setting up the request and triggered an Error

    // dLog('Error', error.message)
    return { ...error, isError: true }
  }
}

export const handleAxiosError = error => parseAxiosError(error)

export const blobToImage = async blob => {
  return await new Promise(resolve => {
    const url = URL.createObjectURL(blob)
    const img = new Image()
    img.onload = () => {
      URL.revokeObjectURL(url)
      resolve(img)
    }
    img.src = url
  })
}

export const dataURIToBlob = dataURI => {
  dataURI = dataURI.replace(/^data:/, "")

  const type = dataURI.match(/image\/[^;]+/)
  const base64 = dataURI.replace(/^[^,]+,/, "")
  const arrayBuffer = new ArrayBuffer(base64.length)
  const typedArray = new Uint8Array(arrayBuffer)

  for (let i = 0; i < base64.length; i++) {
    typedArray[i] = base64.charCodeAt(i)
  }

  return new Blob([arrayBuffer], { type })
}
